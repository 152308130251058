import Cookies from 'js-cookie';
import {
    FILTER,
    SUBFILTER,
    GRADE,
    SUBJECT
} from './mutation-types.js';

const domain = process.env.PATH_TYPE === 'development' ? '' : '.gtqscz.com';

export default {
    TOKEN(state, payload) {
        console.log('mutations TOKEN state, payload: ', state, payload);
        if (process.server) {
            state.token = payload;
        }
        else {
            Cookies.set('__user_token__', payload, {
                path: '/',
                domain
            });
            Cookies.set('sid', payload, {
                domain,
                Secure: true,
                SameSite: 'None',
                path: '/',
                expires: 30
            });
            state.token = payload;
        }
    },
    USER_INFO(state, payload) {
        if (process.server) {
            state.userInfo = payload;
        }
        else {
            Cookies.set('__user_info__', JSON.stringify(payload), {
                path: '/',
                domain
            });
            Cookies.set('uid', payload.userId, {
                expires: 30,
                path: '/',
                domain
            });
            state.userInfo = payload;
        }
    },
    EXTRA_PROFILE(state, payload) {
        state.extraProfile = payload;
    },
    CLEAR_TOKEN(state) {
        Cookies.remove('__user_token__', {path: '/', domain});
        Cookies.remove('sid', {path: '/', domain});
        Cookies.remove('__user_info__', {path: '/', domain});
        Cookies.remove('uid', {path: '/', domain});
        state.token = null;
        state.userInfo = null;
        state.extraProfile = null;
    },
    [FILTER](state, payload) {
        state.filter = payload;
    },
    [SUBFILTER](state, payload) {
        state.subfilter = payload;
    },
    [SUBJECT](state, payload) {
        state.subject = payload.subject;
    },
    [GRADE](state, payload) {
        if (process.server) {
            state.grade = payload.grade;
        }
        else {
            state.grade = payload.grade;
            Cookies.set('grade', payload.grade);
        }
    },
    SET_WORDS(state, payload) {
        state.words = payload;
    },
    BANNER_LIST(state, payload) {
        state.banners = payload.banners;
    },
};
