export const FILTER = 'FILTER';
export const SUBFILTER = 'SUBFILTER';

export const GRADE = 'GRADE';
export const SUBJECT = 'SUBJECT';

/** 页面级的定义 */

// 班级列表
export const SUBLIST_DATA = 'SUBLIST_DATA';
export const SET_SUBLIST_FINISHED = 'SET_SUBLIST_FINISHED';

// 课程详情
export const COURSE_DETAIL_DATA = 'COURSE_DETAIL_DATA';
