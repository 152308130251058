// element-ui.js
import Vue from 'vue';
import {
    Tooltip,
    Input
} from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';

const components = [
    Tooltip,
    Input
];

const Element = {
    install(Vue) {
        components.forEach(component => {
            Vue.component(component.name, component);
        });
    }
};

Vue.use(Element, {locale});
