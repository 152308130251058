import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';
import getters from './getters.js';
// import mutations from './mutations.js'
import actions from './actions.js';

// import category from './modules/category'
import study from './modules/study';
import course from './modules/course';
import {encode} from '@base/util/common';
import config from '~/environment';

const domain = process.env.PATH_TYPE === 'development' ? '' : '.gtqscz.com';

Vue.use(Vuex);

// eslint-disable-next-line max-lines-per-function
const store = () => {
    return new Vuex.Store({
        state: {
            token: null,
            userInfo: null,
            extraProfile: {
                realName: null,
            },
            stater: {
                status: 0,
                error_info: ''
            },
            filter: null,
            subfilter: null,
            phase: 200,
            direction: 0,
            classify: 0,
            subSubject: 0,
            subjectType: 1,
            grade: 1,
            categoryId: 1,
            subject: 0,
            city: '',
            province: '',
            place: '',
            words: [],
            banners: [],
            // 是否显示登录弹窗
            isShowLogin: false,
            // 是否显示注册弹窗
            isShowRegister: false,
            // 是否显示重置密码弹窗
            isShowForget: false,
            courseId: '',
            redirect: '',
            accessType: 0,
            countryCode: '+86',
            // 是否展示引导气泡
            isShowCategoryBubble: false,
            // 是否进入过选课中心
            hasEnterCategory: false,
            // 项目通用变量 在layouts文件下defaul页面t引入
            commonVariable: {},
            // 题库、资讯等入口
            entrance: {},
            // 帮助中心
            helpCenter: [],
            // 页面tdk，title,description,keywords
            tdk: {},
            // 友情链接
            blogroll: [],
            // 品牌专区相关数据
            utmData: {},
            // 用户注册协议链接地址
            protocalUrl: {
                name: '《用户服务协议》',
				url: 'https://www.gtqscz.com/gen-page/link/c0081e1c-f8ce-4d9d-81ec-2ad29f76a476'
			},
            // 隐私政策链接地址
            policyUrl: {
                name: '《隐私政策》',
				url: 'https://www.gtqscz.com/gen-page/link/2c1b3200-7161-4dc0-9319-e3ec7cb118bb'
			},
            // 侧边栏图片
            sideFixedMenu: {},
            // 官网底部cms标志
            footerCms: 0,
            p_client: 15,
            os: 'h5-pc',
            projectName: 'gtqscz',
            // 上一次点击页面的时间
            lastClickTime: new Date().getTime(),
        },
        getters,
        mutations: {
            SET_LASTCLICK_TIME(state, lastClickTime) {
                state.lastClickTime = lastClickTime;
            },
            SET_ENTRANCE_VOS(state, payload) {
                state.entrance = payload;
            },
            SET_ACCESS_TYPE(state, type) {
                state.accessType = type;
            },
            TOKEN(state, payload) {
                if (process.server) {
                    state.token = payload;
                }
                else {
                    Cookies.set('__user_token__', payload, {
                        expires: 30,
                        path: '/',
                        domain
                    });
                    Cookies.set('sid', payload, {
                        domain,
                        Secure: true,
                        SameSite: 'None',
                        path: '/',
                        expires: 30
					});
					console.log('v---set token--->', payload);
                    state.token = payload;
                }
            },
            USER_INFO(state, payload) {
                const reg = /^\d{11}$/;
                if (payload.mobile && reg.test(payload.mobile)) {
                    payload.mobile = encodeURIComponent(encode(payload.mobile));
                }
                if (process.server) {
                    state.userInfo = payload || {};
                }
                else {
                    Cookies.set('__user_info__', JSON.stringify(payload), {path: '/', domain});
                    state.userInfo = payload || {};
                }
                Cookies.set('uid', state.userInfo.userId, {path: '/', domain});
            },
            EXTRA_PROFILE(state, payload) {
                state.extraProfile = payload;
            },
            CLEAR_TOKEN(state) {
                Cookies.remove('__user_token__', {path: '/', domain});
                Cookies.remove('sid', {path: '/', domain});
                Cookies.remove('uid', {path: '/', domain});
                Cookies.remove('__user_info__', {path: '/', domain});
                Cookies.remove('course_start_time');
                state.token = null;
                state.userInfo = null;
                state.extraProfile = null;
            },
            FILTER(state, payload) {
                state.filter = payload;
            },
            SUBFILTER(state, payload) {
                state.subfilter = payload;
            },
            STATER(state, payload) {
                state.stater = payload;
            },
            SUBJECT(state, payload) {
                state.subject = payload.subject;
            },
            GRADE(state, payload) {
                if (process.server) {
                    state.grade = payload.grade;
                }
                else {
                    state.grade = payload.grade;
                    Cookies.set('grade', payload.grade);
                }
            },
            SET_PHASE(state, payload) {
                if (process.server) {
                    state.phase = payload.phase;
                }
                else {
                    state.phase = payload.phase;
                    Cookies.set('phase', payload.phase);
                }
            },
            SET_DIRECTION(state, payload) {
                if (process.server) {
                    state.direction = payload.direction;
                }
                else {
                    state.direction = payload.direction;
                    Cookies.set('direction', payload.direction);
                }
            },
            SET_CLASSIFY(state, payload) {
                if (process.server) {
                    state.classify = payload.classify;
                }
                else {
                    state.classify = payload.classify;
                    Cookies.set('classify', payload.classify);
                }
            },
            SET_SUBSUBJECT(state, payload) {
                if (process.server) {
                    state.subSubject = payload.subSubject;
                }
                else {
                    state.subSubject = payload.subSubject;
                    Cookies.set('subSubject', payload.subSubject);
                }
            },
            SET_SUBJECTTYPE(state, payload) {
                if (process.server) {
                    state.subjectType = payload.subjectType;
                }
                else {
                    state.subjectType = payload.subjectType;
                    Cookies.set('subjectType', payload.subjectType);
                }
            },
            SET_CATEGORYID(state, payload) {
                if (process.server) {
                    state.categoryId = payload.categoryId;
                }
                else {
                    state.categoryId = payload.categoryId;
                    Cookies.set('categoryId', payload.categoryId);
                }
            },
            // 存储地址
            SET_PLACE(state, payload) {
                if (process.server) {
                    state.place = payload.place;
                }
                else {
                    Cookies.set('placeIndex', payload.place);
                    state.place = payload.place;
                }
            },
            // 存储所在市
            SET_CITY(state, payload) {
                if (process.server) {
                    state.city = payload.city;
                }
                else {
                    Cookies.set('cityIndex', payload.city);
                    state.city = payload.city;
                }
            },
            // 存储所在省
            SET_PROVINCE(state, payload) {
                if (process.server) {
                    state.province = payload.province;
                }
                else {
                    Cookies.set('provinceIndex', payload.province);
                    state.province = payload.province;
                }
            },
            SET_WORDS(state, payload) {
                state.words = payload;
            },
            BANNER_LIST(state, payload) {
                state.banners = payload.banners;
            },
            SET_ISSHOWLOGIN(state, payload) {
                state.isShowLogin = payload;
            },
            // SET_ISSHOWREGISTER(state, payload) {
            //     state.isShowRegister = payload;
            // },
            SET_ISSHOWFORGET(state, payload) {
                state.isShowForget = payload;
            },
            SET_COURSEID(state, payload) {
                state.courseId = payload;
            },
            SET_REDIRECT_URL(state, payload) {
                state.redirect = payload;
            },
            // 保存海外区号
            SET_REGION_CODE(state, payload) {
                state.countryCode = payload;
            },
            // 控制是否展示选课中心引导气泡
            SET_ISSHOWCATEGORYBUBBLE(state, paylaod) {
                state.isShowCategoryBubble = paylaod;
            },
            // 记录是否进入过选课中心
            SET_HASENTERCATEGORY(state, payload) {
                Cookies.set('hasEnterCategory', payload);
                state.hasEnterCategory = payload;
            },
            // 项目通用变量
            SET_COMMONVARIAVBLE(state, payload) {
                state.commonVariable = payload;
            },
            // 侧边栏图片
            SET_SIDE_FIXED_MENU(state, payload) {
                state.sideFixedMenu = payload;
            },
            // footer
            SET_FOOTER_CMS(state, payload) {
                state.footerCms = payload;
            },
            // 帮助中心文档
            SET_HELPCENTER(state, paylaod) {
                state.helpCenter = paylaod;
            },
            SET_TDK(state, paylaod) {
                state.tdk = paylaod;
            },
            SET_BLOGROLL(state, paylaod) {
                state.blogroll = paylaod;
            },
            SET_UTM_DATA(state, payload) {
                state.utmData = payload;
            },
            // 用户注册协议和隐私政策 地址
            SET_PROTOCOL_URL(state, payload) {
                const protocalUrl = payload.find(item => item.name === '用户注册协议')?.idOrUrl;
                const policyUrl = payload.find(item => item.name === '隐私政策')?.idOrUrl;

                state.protocalUrl.url = protocalUrl ? `https://${config.env.pcUrl}/html/${protocalUrl}` : '/protocal';
                state.policyUrl.url = policyUrl ? `https://${config.env.pcUrl}/html/${policyUrl}` : '/policy';
            }
        },
        actions,
        modules: {
            study,
            course
        }
    });
};

export default store;
