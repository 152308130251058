import Vue from 'vue';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';

const pkg = require('~/package');


Raven.config('//f089c0673cfa4302adb36d3ca51b8c58@sentry.baijia.com/108', {
    release: pkg.version,
    ignoreErrors: [
        "Cannot read property 'postMessage' of null"
    ],
    tags: {
        errorTags: 'pgerrors'
    }
}).addPlugin(RavenVue, Vue).install();
