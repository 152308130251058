/* eslint-disable babel/new-cap */
import api from '~/api';
import PopmotionDetail from '@base/util/class/learn/PromotionDetail';

const noop = function () {};

const state = () => ({
    courseId: '',
    courseType: '',
    activityNumber: '',
    productType: '',
    // 课程现价
    price: '',
    // 优惠促销信息(限时限额)
    promotionUrgeInfo: null,
    // 报名按钮状态
    goodsPayStatus: {},
    // 班级详情信息
    goodsDetailInfo: {},
    // 优惠信息
    promotionDetailList: [],
    // 二维码信息
    qrCodeUrl: '',
    // 是否服务端渲染
    productHasServerRender: false,
});

const mutations = {
    SET_BASE_INFO(state, {courseId, courseType, activityNumber, price, productType}) {
        state.courseId = courseId;
        state.courseType = courseType;
        state.activityNumber = activityNumber;
        state.price = price;
        state.productType = productType;
    },
    SET_PROMOTION_URGE_INFO(state, payload) {
        state.promotionUrgeInfo = payload;
    },
    SET_PROMOTION_DETAIL(state, payload) {
        state.promotionDetailList = payload;
    },
    // 设置报名按钮状态
    SET_GOODS_PAY_STATUS(state, payload) {
        state.goodsPayStatus = payload;
    },
    // 设置课程详情信息
    SET_GOODS_DETAIL_INFO(state, payload) {
        state.goodsDetailInfo = payload;
    },
    // 报名二维码信息
    SET_QR_CODE_URL(state, payload) {
        state.qrCodeUrl = payload;
    },
    // 链接直接访问
    SET_HAS_SERVER_RENDER(state, payload) {
        state.productHasServerRender = payload;
    },
};

const actions = {
    // 获取优惠区域、优惠弹窗数据
    async GET_PROMOTION_DETAIL({commit, state}, payload = {}) {
        const {callback = noop} = payload;
        const {courseId, courseType, activityNumber, price} = state;

        if (courseId === '') {
            return;
        }

        let params = {};
        let key = courseType === 23 ? 'activityNumber' : 'clazzNumber';
        params[key] = courseId;

        // 只有在单课并且有activity_number时再传activity_number
        if (+activityNumber !== 0 && key !== 'activityNumber') {
            params.activityNumber = activityNumber;
        }

        const res = await this.$axios.$post(api.course.promotionDetail, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const {code, data = {}} = res;
        let promotionUrgeInfo = null;
        if (code === 0) {
            const isLianBao = courseType === 23;
            let {promotionList = [], promotionPrice} = data;
            // eslint-disable-next-line no-undefined
            if (promotionPrice === undefined) {
                promotionPrice = -1;
            }
            if (Array.isArray(promotionList)) {
                let promotionCountDown = null;
                promotionList.forEach(item => {
                    const newItem = new PopmotionDetail(item);
                    const {strType, remainingTime, remainingCount} = newItem;
                    // promotionPrice大于等于0且小于现价,表示存在优惠
                    if (promotionPrice >= 0 && promotionPrice < price) {
                        // 目前只有单课的直减优惠,联报的联报优惠有限时限额,去掉优惠下的直减/联报标签
                        if ((isLianBao && strType === '联报') || (!isLianBao && strType === '直减')) {
                            if ((remainingTime || remainingCount)) {
                                promotionCountDown = {
                                    remainingTime,
                                    remainingCount
                                };
                            }
                        }
                    }
                });
                // promotionPrice大于等于0且小于现价,表示存在优惠
                if (promotionPrice >= 0 && promotionPrice < price) {
                    promotionUrgeInfo = {
                        promotionPrice,
                        promotionBaseTimestamp: Date.now(),
                        promotionCountDown
                    };
                }
            }
        }
        commit('SET_PROMOTION_URGE_INFO', promotionUrgeInfo);
        callback(res);
        return res;
    },
    // 新详情页获取优惠区域、优惠弹窗数据
    async GET_PROMOTION_DETAIL_NEW({commit, state}, payload = {}) {
        const {callback = noop} = payload;
        const {courseId, courseType, price, productType} = state;

        if (courseId === '') {
            return;
        }

        const newPromotionApi = api.course.coursePromotion;
        const params = {
            products: {
                productType,
                productNumber: courseId
            }
        };

        const res = await this.$axios.$post(newPromotionApi, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const {code, data = {}} = res;
        let promotionUrgeInfo = null;
        const newPromotionList = [];
        if (code === 0) {
            const isLianBao = courseType === 23;
            let [{promotionList = [], promotionPrice} = {}] = data || [];
            // eslint-disable-next-line no-undefined
            if (promotionPrice === undefined) {
                promotionPrice = -1;
            }
            if (Array.isArray(promotionList)) {
                let promotionCountDown = null;
                promotionList.forEach(item => {
                    const newItem = new PopmotionDetail(item);
                    const {strType, remainingTime, remainingCount} = newItem;
                    // promotionPrice大于等于0且小于现价,表示存在优惠
                    if (promotionPrice >= 0 && promotionPrice < price) {
                        // 目前只有单课的直减优惠,联报的联报优惠有限时限额,去掉优惠下的直减/联报标签
                        if ((isLianBao && strType === '联报') || (!isLianBao && strType === '直减')) {
                            if ((remainingTime || remainingCount)) {
                                promotionCountDown = {
                                    remainingTime,
                                    remainingCount
                                };
                            }
                        }
                    }
                    newPromotionList.push(newItem);
                });
                // promotionPrice大于等于0且小于现价,表示存在优惠
                if (promotionPrice >= 0 && promotionPrice < price) {
                    promotionUrgeInfo = {
                        promotionPrice,
                        promotionBaseTimestamp: Date.now(),
                        promotionCountDown
                    };
                }
            }
        }
        commit('SET_PROMOTION_DETAIL', newPromotionList);
        commit('SET_PROMOTION_URGE_INFO', promotionUrgeInfo);
        callback(res);
        return res;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
