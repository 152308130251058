import view from 'habo/es/view';
import {getUserInfo} from '@base/util/common';

const vport = json => {
    const {
        trackId,
        userId,
        userNumber
    } = getUserInfo();
    const data = Object.assign(json, {
        trackId,
        userId,
        userNumber
    });
    view(data);
};

export default ({app}) => {
    app.router.afterEach((to, from) => {
        if (!to.name) {
            return;
        }
        const charsKey = '_id';
        const path = to.name.split('-');
        let routePath = path.join('-');
        if (path.length >= charsKey.length - 1) {
            const lastPath = to.name.split(path[1])[1];
            routePath = `${path[0]}`;
            if (lastPath) {
                routePath = `${path[0]}/${path[1]}${lastPath.split(charsKey).join('')}`;
            }
        }
        vport({
            page_str: `/gaotu/pc/${routePath}`
        });
        // eslint-disable-next-line no-underscore-dangle
        window._czc && window._czc.push(['_trackEvent', `page-${path}`, 'show', ' ', 1, `page-${path}`]);
    });
};
