import Vue from 'vue';
import 'babel-polyfill';
import {isWebP} from '@base/util/common';
import {CHINESE_MOBILE_REGEXP, FOREIGN_MOBILE_REGEXP_WITHOUT_REGION_CODE} from '@base/constants/mobileReg';


Vue.prototype.imageFormat = (width, height) => {
    return isWebP()
        ? `?x-oss-process=image/resize,h_${height},w_${width}/format,webp`
        : `?x-oss-process=image/resize,m_lfit,h_${height},w_${width}`;
};

/**
 *  直播回放限制时间
 */
Vue.prototype.divisionTime = (new Date('2019/01/20 00:00:00')).getTime() / 1000;

/**
  * 校验手机号, 如果是国外手机号（ 带区号）， 规则为 最少7位 最多11位； 如果是国内手机号 就按照已有规则 /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/校验
  *
  * @param {string} mobile 手机号
  * @param {string} regionCode 地区区号
  * @return 手机号是否合法
  */
Vue.prototype.validateMobile = function validateMobile(mobile) {

    // 鉴于多个文件使用该方法，为了改动最小化，直接在函数内部访问store
    // 为了尽量不影响 validateMobile 函数使用的场景，添加了判断
    const countryCode = this.$store.state && this.$store.state.countryCode;
    if (countryCode && countryCode !== '+86') {
        return FOREIGN_MOBILE_REGEXP_WITHOUT_REGION_CODE.test(mobile);
    }

    return CHINESE_MOBILE_REGEXP.test(mobile);
    // eslint-enable babel/no-invalid-this
};
