/* 哈勃 */
/* eslint-disable no-undef */
import habo from 'habo-pc';
import Vue from 'vue';
import {getUserInfo} from '@base/util/common';

// const {utmData = {}} = window.__NUXT__?.state;

const utm_source = window.sessionStorage.getItem('utm_source') || '';
const utm_term = window.sessionStorage.getItem('utm_term') || '';

/**
 * habo基础配置
 */
habo.setConfig({
    NAME: 'gt-pc',
    VERB: 'get',
    appId: 'gaotu_pc'
});

/**
 * habo性能统计
 */
habo.perf.setConfig({
    // 添加path路径
    path() {
        return window.location.pathname.replace(/\/(\d+)$/g, '');
    }
});

/**
 * 自定义点击事件上报habo
 * @param eventId 事件id
 * @param other 扩展配置
 */
habo.haboClickReport = (eventId = 0, other = {}) => {
    // 哈勃
    const params = Object.assign(getUserInfo(), {eventId}, other);
    params.utm_source = utm_source;
    params.utm_term = utm_term;
    habo.click(params);
    // 友盟
    _czc.push(['_trackEvent', eventId, 'click', ' ', 1, '']);
};

/**
 * 自定义指令点击事件上报哈勃
 * `v-haboClickReport`
 * 支持数字和对象（例如：v-haboClickRepor="12345"；v-haboClickRepor="{eventId: 12345}"）
 */
Vue.directive('haboClickReport', {
    inserted(el, binding) {
        el.addEventListener('click', () => {
            if (binding.value) {
                const eventObj = typeof binding.value === 'number' ? {eventId: binding.value} : binding.value;
                const params = Object.assign(getUserInfo(), eventObj);
                params.utm_source = utm_source;
                params.utm_term = utm_term;
                habo.click(params);
                _czc.push(['_trackEvent', params.eventId || 0, 'click', ' ', 1, '']);
            }
        });
    }
});

/**
 * （PV）自定义查看事件上报habo
 * @param eventId 事件id
 * @param other 扩展配置
 */
habo.haboViewPage = (eventId = 0, other = {}) => {
    const params = Object.assign(getUserInfo(), {eventId}, other);
    params.utm_source = utm_source;
    params.utm_term = utm_term;
    habo.view(params);
    _czc.push(['_trackEvent', eventId, 'click', ' ', 1, '']);
};

/** *********** 老版本哈勃封装的方法 **************/

// 注册全局自定义指令 `v-clickReport`
Vue.directive('clickReport', {
    inserted(el, binding) {
        el.addEventListener('click', () => {
            if (binding.value) {
                const eventObj = typeof binding.value === 'number' ? {eventId: binding.value} : binding.value;
                const params = Object.assign(getUserInfo(), eventObj);
                params.utm_source = utm_source;
                params.utm_term = utm_term;
                habo.click(params);
            }
        });
    }
});

Vue.prototype.$habo = habo;
