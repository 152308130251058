import Vue from 'vue';
import SvgIcon from '@/components/common/SvgIcon';

// 注册全局组件
Vue.component('svg-icon', SvgIcon);

// 引用icon图标
const requireAll = requireContext => requireContext.keys().map(requireContext);
// 遍历assets/svg文件夹中的所有.svg文件， 第二个参数为true时遍历子文件夹，为false时不遍历子文件夹
const req = require.context('@/assets/svg', true, /\.svg$/);
requireAll(req);
