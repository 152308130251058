import api from '~/api';

const state = () => ({
    type: 1,
    pagesize: 9,
    finished: true,
    total_count: 0,
    week_time_id: 0,
    quantum_time_id: 0,
    season_id: 0,
    courseList: [],
    isFetching: false,
});

const getters = {
    pagesize: state => state.pagesize
};

const mutations = {
    IS_FETCHING(state, payload) {
        state.isFetching = payload;
    },
    COURSE_LIST(state, payload) {
        state.courseList = payload;
    },
    TOTAL_COUNT(state, payload) {
        state.total_count = payload;
    },
    CATEGORY_FINISHED(state, payload) {
        state.finished = payload;
    },
    TYPE(state, payload) {
        state.type = payload;
    },
    WEEK_TIME(state, payload) {
        state.week_time_id = payload;
    },
    SEASON_ID(state, payload) {
        state.season_id = payload;
    },
    QUANTUM_TIME(state, payload) {
        state.quantum_time_id = payload;
    },
};

const actions = {
    async loadCategoryCourseListData({commit, state, getters}, payload = {}) {
        const params = {
            type: payload.type || state.type,
            grade: payload.grade || getters.grade,
            subject: payload.subject || getters.subject,
            week_time_id: state.week_time_id,
            quantum_time_id: state.quantum_time_id,
            pagesize: state.pagesize,
            season: payload.season || state.season_id
        };
        if (payload.last_course_id) {
            params.last_course_id = payload.last_course_id;
        }
        else {
            commit('IS_FETCHING', true);
        }
        if (payload.type) {
            commit('TYPE', payload.type);
        }
        await this.$axios.$get(api.course.list, {params}).then(res => {
            commit('IS_FETCHING', false);
            if (res.status === 0) {
                if (params.last_course_id) {
                    commit('COURSE_LIST', [...state.courseList, ...res.courses]);
                }
                else {
                    commit('COURSE_LIST', res.courses || []);
                    commit('TOTAL_COUNT', res.total_count || 0);
                }
                commit('CATEGORY_FINISHED', false);
                if (res.courses.length < state.pagesize) {
                    commit('CATEGORY_FINISHED', true);
                }
            }
        });
    }
};

export default {
    actions,
    getters,
    state,
    mutations
};
