export default {
    token: state => state.token,
    direction: state => state.direction,
    classify: state => state.classify,
    subSubject: state => state.subSubject,
    subjectType: state => state.subjectType,
    phase: state => state.phase,
    categoryId: state => state.categoryId,
    grade: state => state.grade,
    place: state => state.place,
    province: state => state.province,
    city: state => state.city,
    userInfo: state => state.userInfo,
    stater: state => state.stater,
    subject: state => state.subject
};
