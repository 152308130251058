// 百度、搜狗、360、bing、google 百度统计搜索引擎来源
// baidu.com、sogou.com、so.com、bing.com、google.com

const refer = document.referrer;
const reg = /baidu.com|sogou.com|so.com|bing.com|google.com/ig;

try {
    const source = window.sessionStorage.getItem('utm_source');
    if (reg.test(refer) && !source) {
        const {utm_source = '', utm_term = ''} = window.__NUXT__.state.utmData || {};
        if (utm_source) {
            window.sessionStorage.setItem('utm_source', utm_source);
            window.sessionStorage.setItem('utm_term', utm_term);
        }
        else {
            window.sessionStorage.setItem('utm_source', 'seo');
            window.sessionStorage.setItem('utm_term', 'seo');
        }
    }
}
catch (error) {
    console.error(error);
}

