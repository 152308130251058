/* eslint-disable no-underscore-dangle */
import * as types from '@/store/mutation-types';
import api from '~/api';
import Cookies from 'js-cookie';
import qs from 'qs';
import Logger from '@base/util/logger';
import getUuid from '@base/util/uuid';
import axios from 'axios';
/* eslint-disable complexity */
export default {
    nuxtServerInit({dispatch, commit, getters}, {app, req, res, redirect, route}) {
        const userAgent = req.headers['user-agent'];
        const {utm_source = '', utm_term = ''} = route.query;

        if (req.headers.cookie) {
            const cookie = req.headers.cookie ? req.headers.cookie.split('; ') : [];
            let i = 0;
            let cookies = [];
            const cookieJson = {};
            let key = '';
            let value = '';
            for (; i < cookie.length; i++) {
                cookies = cookie[i].split('=');
                key = cookies[0];
                value = cookies[1];
                cookieJson[key] = value;
            }
            commit(types.GRADE, {
                grade: cookieJson.grade || 109
            });
            commit('SET_PHASE', {
                phase: cookieJson.phase || 200
            });
            commit('SET_DIRECTION', {
                direction: cookieJson.direction || 0
            });
            commit('SET_CLASSIFY', {
                classify: cookieJson.classify || 0
            });
            commit('SET_SUBSUBJECT', {
                subSubject: cookieJson.subSubject || 0
            });
            commit('SET_SUBJECTTYPE', {
                subjectType: cookieJson.subjectType || 1
            });
            commit('SET_CATEGORYID', {
                categoryId: cookieJson.categoryId || 101060
            });
            commit('SET_UTM_DATA', {
                utm_source,
                utm_term
            });
            if (cookieJson.__user_token__) {
                commit('TOKEN', cookieJson.__user_token__);
            }
            if (cookieJson.__user_info__) {
                try {
                    commit('USER_INFO', JSON.parse(cookieJson.__user_info__.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent)));
                }
                catch (e) {}
            }
            if (cookieJson.placeIndex) {
                commit('SET_PLACE', {place: cookieJson.placeIndex});
            }

            if (cookieJson.provinceIndex) {
                commit('SET_PROVINCE', {province: cookieJson.provinceIndex});
            }

            if (cookieJson.cityIndex) {
                commit('SET_CITY', {city: cookieJson.cityIndex});
            }
            if (cookieJson.hasEnterCategory) {
                commit('SET_HASENTERCATEGORY', cookieJson.hasEnterCategory);
            }
        }
        // 获取官网的一些配置信息

        dispatch('GET_CMS_FLAG');
        // 应用初始化的时候会先走这里，所以在这里收集服务端日志收集
        const uuid = getUuid();
        this.$axios.trackId = uuid;
        new Logger().render('服务器接收请求[node.server]', {
            trackId: uuid,
            path: req.url,
            UA: userAgent,
            referer: req.headers.referer
        });
    },

    /**
     * 筛选数据
     * @param {*} param0
     * @param {*} payload
     */
    async fetchFilterData({commit, state, getters}, payload) {
        await this.$axios.$get(api.course.filter).then(res => {
            if (res.status === 0) {
                const grades = res.grade_filter.filter_sections
                    .reduce((calc, item) => calc.concat(item.filter_items), [])
                    .map(value => value.index);
                if (grades.indexOf(parseInt(getters.grade, 10)) === -1) {
                    commit(types.GRADE, {
                        grade: grades[0]
                    });
                }
                commit(types.FILTER, res);
            }
        });
    },
    async fetchSubilterData({commit, state, getters}, payload) {
        const params = {
            grade: payload.grade,
            subject: payload.subject,
            course_type: payload.type
        };
        await this.$axios.$get(api.course.subFilter, {params}).then(res => {
            if (res.status === 0) {
                commit(types.SUBFILTER, res);
            }
        });
    },
    // 热词获取
    async GET_HOT_WORDS({commit}) {
        await this.$axios.$get(api.search.getHotWords).then(res => {
            const {status, data = {}} = res;
            if (status === 0) {
                commit('SET_WORDS', data.words || []);
            }
        });
    },

    /**
     * 首页轮播图
     * @param {*} param0
     * @param {*} payload
     */
    async BANNER_LIST({commit, state, getters}, payload) {
        state.banners = '';
        const params = {
            grade: Cookies.get('grade') || 7
        };
        await this.$axios.$get(api.banner.list, {params}).then(res => {
            if (res.status === 0) {
                if (res.data) {
                    commit('BANNER_LIST', res.data);
                }
            }
        });
    },
    // 获取用户额外信息
    async EXTRA_PROFILE({commit}) {
        await this.$axios.$get(api.user.extraProfile).then(res => {
            const {code, data} = res;
            if (code === 0) {
                commit('EXTRA_PROFILE', data);
            }
        });
    },

    /**
     * 看直播
     * @param {*} param0
     * @param {*} payload
     */
    async GET_LIVE_URL({commit, state, getters}, payload) {
        const params = {
            liveId: payload.live_id
        };
        if (payload.counselor_id) {
            params.counselorId = payload.counselor_id;
        }
        const newWindow = window.open();
        await this.$axios.$post(api.live.login_web, qs.stringify(params)).then(res => {
            if (res.code === 0) {
                // window.location.href = res.live_login_url
                if (res.data && res.data.liveLoginUrl) {
                    newWindow.location.href = `${res.data.liveLoginUrl}&login_time=${Date.now()}`;
                }
            }
        });
    },
    async GET_LIVE_URL_W({commit, state, getters}, payload) {
        const params = {
            liveId: payload.live_id,
            nextLiveId: payload.next_live_id,
        };
        if (payload.counselor_id) {
            params.counselorId = payload.counselor_id;
        }
        // let newWindow = window.open()
        await this.$axios.$post(api.live.login_web, qs.stringify(params)).then(res => {
            if (res.code === 0) {
                if (res.data && res.data.liveLoginUrl) {
                    window.location.href = `${res.data.liveLoginUrl}&login_time=${Date.now()}`;
                }
                // newWindow.location.href = res.live_login_url
            }
        });
    },

    /**
     * 看回放
     * @param {*} param0
     * @param {*} payload
     */
    async GET_PLAYBACK_URL({commit, state, getters}, payload) {
        const params = payload;
        const newWindow = window.open();
        await this.$axios.$post(api.live.playback, qs.stringify(params)).then(res => {
            if (res.code === 0) {
                // window.location.href = res.gsx_playback_info.m_url
                if (res.data && res.data.signinLivePlayback && res.data.signinLivePlayback.pcUrl) {
                    newWindow.location.href = res.data.signinLivePlayback.pcUrl;
                }
            }
        });
    },

    /**
     * 登录成功之后做的事情
     */

    AFTER_LOGIN_SUCCESS({commit, state}) {
        if (state.redirect) {
            window.location.href = decodeURIComponent(state.redirect);
            commit('SET_REDIRECT_URL', '');
        }
    },
    // 类直播、即时视频
    OPEN_LIVE_VIDEO_URL({commit, state, getters}, payload) {
        const params = payload;
        this.$axios
            .$post(api.live.videoLive, params, {headers: {'Content-Type': 'application/json'}})
            .then(res => {
                const {code, data = {}} = res;
                if (code === 0) {
                    const videoLiveDTO = data.videoLiveDTO || {};
                    window.location.href = `${videoLiveDTO.pcUrl}&login_time=${Date.now()}`;
                }
            });
    },
    // 请求题库、学习资讯、学习资料等入口
    GET_ENTRANCE({commit}, payload) {
        this.$axios.$get(api.chunsun.recommend).then(res => {
            if (res.code === 0) {
                commit('SET_ENTRANCE_VOS', {entranceVos: res.data.entranceVos, entranceStatus: 0});
            }
            else {
                commit('SET_ENTRANCE_VOS', {entranceVos: [], entranceStatus: 0});
            }
        }).catch(e => {
            commit('SET_ENTRANCE_VOS', {entranceVos: [], entranceStatus: 0});
        });
    },
    // 获取友情链接
    GET_BLOGROLL({commit}, payload) {
        // 友情链接   hydra 平台
        // 暂时使用hydra 平台开发，后续摇光支持 友情链接配置后，采用摇光接口。
        const HYDRA_URL = 'https://hydra-cdn.gsxcdn.com/activity-fe/6027415516627696180.json';

        this.$axios.$get(HYDRA_URL).then(res => {
            if (res.code === 0) {
                commit('SET_BLOGROLL', res.data?.list);
            }
        }).catch(e => {
            commit('SET_BLOGROLL', []);
        });
    },
    GET_CMS_FLAG({commit}) {
        // 引入通用变量,项目共用 hydra名称 cms-底部信息配置
        const ApolloConfigId = process.env.PATH_TYPE === 'master' ? '6247067946268331126' : '6244176856755610530';
        const ApolloUrl = `https://hydra-cdn.gsxcdn.com/activity-fe/${ApolloConfigId}.json?t=${Date.now()}`;
        axios
            .get(ApolloUrl)
            .then(res => {
				const { data = {} } = res.data;
				const commonVariable = data.commonVariable ? { ...data.commonVariable, codeLogin:false} : {};
                commit('SET_FOOTER_CMS', data.cms || 0);
				commit('SET_COMMONVARIAVBLE', commonVariable);
                commit('SET_SIDE_FIXED_MENU', data.sideFixedMenu || {});
            });
    }
};

