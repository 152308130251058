import magic from '@magic-microservices/magic';
import * as ExerciseTool from '@base/exercise';

// 全局注册组件
magic('exercise-tool', ExerciseTool, {
    // 这里很重要，web components 要求一定要显示声明属性
    propTypes: {
        questionId: String,
        editable: Boolean,
        stem: String,
        options: String,
        answer: String,
        analysis: String,
        type: Number,
        id: String || Number
    },
});
