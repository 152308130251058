import Vue from 'vue';
import habo from 'habo';
import {config, ready, onError} from '@umeng/source-sdk';
import Cookies from 'js-cookie';

// 百度、搜狗、360、bing、google 百度统计搜索引擎来源
// baidu.com、sogou.com、so.com、bing.com、google.com

const refer = document.referrer;
const reg = /baidu.com|sogou.com|so.com|bing.com|google.com/ig;

// seo leads_source
const SEO_LEADS_SOURCE = reg.test(refer) ? 'sys176902351021422592z' : null;

export default function sourceInit({query}) {
    const {leads_source = null, from_leads = null} = query;
    const gid = Cookies.get('GID') || '';
    // 品专与seo过来的自然流量，链接有leads_source 则是品专流量
    config({
        sourceId: leads_source ? leads_source : SEO_LEADS_SOURCE,
        env: process.env.PATH_TYPE === 'master' ? 'prod' : 'test',
        gid
    }, {
        cacheInSession: true
    });
    // error handler
    onError(error => {
        const {message} = error;
        console.log(message);
    });
    // 生成leads后 增加哈勃公参，增加sourceLeads全局变量
    ready(leadsId => {
        if (leadsId) {
            habo.setPublicParams({
                channel_leads: leadsId
            });
            Vue.prototype.$sourceLeads = from_leads || leadsId;
        }
    });
}
